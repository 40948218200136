/* eslint-disable no-unused-vars */
import axios from "../../axios";
import { useState } from "react";
import { ErrorHandler } from "../../utils/ErrorHandler";

export const useMensenCreateIntent = () => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState();

  const createMensenIntent = async (values) => {
    setLoading(true);
    try {
      const { data } = await axios.post("/mensen/create-intent", {
        amount: values.amount * 100,
        currency: "INR",
        name: values.name,
        email: values.email,
      });

      window.location.href = data.fwdUrl;
    } catch (error) {
      ErrorHandler(error, setStatus);
    } finally {
      setLoading(false);
    }
  };

  return { createMensenIntent, loading };
};
