import React from "react";
import GridBackground from "./components/UI/GridBackground";
import ContainerCCWTPMensen from "./components/CustomerChooseWhatToPay/Mensen/ContainerCCWTPMensen";

const CCWTPMensen = () => {
  return (
    <div>
      <GridBackground>
        <ContainerCCWTPMensen />
      </GridBackground>
    </div>
  );
};

export default CCWTPMensen;
