/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import InputField from "../InputField";
import { fieldNames } from "../../../constants/fieldNames";
import { Form, Button } from "antd";
import InputNumberField from "../InputNumberField";
import { Divider } from "antd";
import { useMensenCreateIntent } from "../../../hooks/CustomerChooseWhatToPay/useMensenCreateIntent";

const DetailsMensen = () => {
  const [form] = Form.useForm();
  const { createMensenIntent, loading } = useMensenCreateIntent();

  const onFinish = async (values) => {
    await createMensenIntent(values);
  };

  return (
    <div className="flex flex-col gap-5 mt-8 font-sans">
      <Form
        form={form}
        name={`AMACreateIntentForm`}
        className="font-sans"
        layout="vertical"
        autoComplete="on"
        requiredMark={"optional"}
        onFinish={onFinish}
      >
        <InputField
          name={fieldNames.name}
          label="Name"
          required
          placeholder={"Enter your name"}
        />
        <InputField
          name={fieldNames.email}
          label="Email"
          required
          placeholder={"Enter your email"}
        />
        <InputNumberField
          name={fieldNames.amount}
          label="Amount"
          required
          placeholder={"Enter your amount"}
          prefix={"₹"}
          addonAfter={"INR"}
        />
        <Divider />
        <div className="text-[16px]">
          Paying to <strong>My Home Work Assist</strong>
        </div>
        <Form.Item noStyle>
          <Button
            size="large"
            className="mt-4 font-sans font-semibold"
            block
            loading={loading}
            htmlType="submit"
          >
            Continue
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default DetailsMensen;
