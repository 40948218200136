import React from "react";
import { Form, InputNumber } from "antd";
import { getValidationRules } from "../../utils/validations";

const InputNumberField = ({
  label,
  name,
  required,
  placeholder,
  prefix,
  addonAfter,
}) => {
  return (
    <Form.Item
      hasFeedback
      label={<div className="text-[#374151]">{label} </div>}
      name={name}
      validateFirst
      rules={getValidationRules(name, required, label)}
      className="w-full font-sans"
    >
      <InputNumber
        size="large"
        name={name}
        placeholder={placeholder}
        className="w-full font-sans"
        prefix={prefix}
        addonAfter={addonAfter}
      />
    </Form.Item>
  );
};

export default InputNumberField;
