import React from "react";
import GridBackground from "./components/UI/GridBackground";
import ContainerCCWTP from "./components/CustomerChooseWhatToPay/AMA/ContainerCCWTP";

const CustomerChooseWhatToPayV3 = () => {
  return (
    <div>
      <GridBackground>
        <ContainerCCWTP />
      </GridBackground>
    </div>
  );
};

export default CustomerChooseWhatToPayV3;
