import React from "react";
import Loader from "../../UI/Loader";
import successAnimation from "../../../assets/Animations/sucessV3.json";
import { IoCopy } from "react-icons/io5";
import toast from "react-hot-toast";
import SuccessToast from "../../UI/Toasts/SuccessToast";

const MensenSuccessScreen = ({ intentDetails }) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(intentDetails?.xIntentId);
    toast.custom((t) => <SuccessToast t={t} message="Copied to clipboard" />, {
      id: "copyToClipboard",
    });
  };

  return (
    <div>
      <Loader
        json={successAnimation}
        width="416px"
        height="250px"
        loop={false}
        keepLastFrame={true}
        mobileHeight="250px"
        mobileWidth={"100%"}
      />
      <div className="text-xl font-medium text-center">Payment Completed</div>
      <div className="text-sm text-[#838383] text-center pt-2">
        To: My Home Work Assist
      </div>
      <div className="p-4 border border-[#E9E9E9] rounded-lg my-8 overflow-auto">
        <div className="grid grid-cols-[1fr_3fr] gap-3">
          <div className="text-[#838383] text-sm">Name</div>
          <div className="max-w-full overflow-hidden text-sm font-medium text-right whitespace-nowrap text-ellipsis">
            {intentDetails?.customerDetails?.name}
          </div>
          <div className="text-[#838383] text-sm">Email</div>
          <div className="max-w-full overflow-hidden text-sm font-medium text-right whitespace-nowrap text-ellipsis">
            {intentDetails?.customerDetails?.email}
          </div>
          <div className="text-[#838383] text-sm">Description</div>
          <div className="max-w-full overflow-hidden text-sm font-medium text-right whitespace-nowrap text-ellipsis">
            {intentDetails?.description}
          </div>
          {intentDetails?.successAt && (
            <>
              <div className="text-[#838383] text-sm">Paid at</div>
              <div className="max-w-full overflow-hidden text-sm font-medium text-right whitespace-nowrap text-ellipsis">
                {new Intl.DateTimeFormat("en-US", {
                  year: "numeric",
                  month: "short",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                }).format(new Date(intentDetails.successAt))}
              </div>
            </>
          )}
        </div>
        <div className="flex items-center justify-between gap-2 mt-3">
          <div className="text-[#838383] text-sm">Ref. Id</div>
          <div
            className="flex items-center justify-end max-w-full gap-2 text-xs font-medium text-right cursor-pointer"
            onClick={copyToClipboard}
          >
            {intentDetails?.xIntentId}
            <IoCopy size={10} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MensenSuccessScreen;
